@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.company-details-container {
    background: #fff;
    width: 100%;
}

/* Tabs */
.setting-company-tabs {
    display: flex;
    gap: 15px;
    /* margin-bottom: 20px; */
    border-bottom: 1px solid #EDEDED;
    /* padding-bottom: 5px; */
}

.setting-company-tabs button {
    padding: 12px 18px;
    border: none;
    background: transparent;
    color: #00000080;;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px 5px 0 0;
    transition: background 0.3s ease;
}

.setting-company-tabs button.active {
    color: black;
    border-bottom: 2px solid #1E88E5;
}

/* Form Section */
.general-details-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Aligns input fields properly in 3 columns */
.company-detail-form-group {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    margin-bottom: 15px;
    justify-content: space-between;
}

/* Adjusts input group to align within three columns */
.comapany-input-group {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 30%; /* Ensures three fields fit in one row */
    max-width: 30%; /* Prevents fields from growing too large */
}
.company-mobile-input {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
}

.company-mobile-input select {
    border: none;
    border-right: 1px solid #ccc;
    padding: 8px;
    font-size: 14px;
    width: auto;
    min-width: 80px;
    flex-shrink: 0;
    height: 100%;
    outline: none;
    text-align: center;
}

.company-mobile-input input {
    border: none;
    padding: 10px;
    flex-grow: 1;
    font-size: 14px;
    outline: none;
}

.comapany-input-group label {
    font-weight: 500;
    margin-bottom: 5px;
    line-height: 24px;
    font-size: 14px;
    color: #7E89A5;
}

.comapany-input-group input,
textarea {
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    font-size: 14px;
    padding: 8px;
}

/* Input Field Styling */
.company-input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.company-input-field:focus {
    border-color: #1E88E5;
    box-shadow: 0 0 4px rgba(30, 136, 229, 0.4);
}

/* Textarea */
.setting-description {
    width: 84%;

}

.setting-description textarea {
    height: 300px;
    resize: none;
}

/* Action Buttons */
.company-form-actions {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    gap: 10px;
}
.company-setting-edit{
    margin-left: 1%;
    color:#1E88E5;
    font-size: 20px;
    background-color: transparent;
  }
.company-cancel-btn {
    background: white;
    color: black;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #00000033;
    cursor: pointer;
    font-size: 14px;
}

.company-save-btn {
    background: #1E88E5;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 14px;
}
.branding-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.branding-upload-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}

.upload-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.upload-box label {
    font-weight: 500;
    line-height: 24px;
    color: #7E89A5;
    font-size: 14px;
}

.drop-area {
    border: 2px dashed #ccc;
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    background: #f9f9f9;
    cursor: pointer;
    transition: background 0.3s ease;
}

.drop-area:hover {
    background: #f0f0f0;
}

.drop-area p {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
}

.drop-area span {
    font-size: 12px;
    color: #888;
}

/* Branding Buttons */
.branding-form-actions {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.branding-cancel-btn {
    background: white;
    color: black;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #00000033;
    cursor: pointer;
    font-size: 14px;
}

.branding-save-btn {
    background: blue;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 14px;
}
.locale-info-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.locale-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.locale-field {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.locale-field label {
    font-weight: 500;
    line-height: 24px;
    font-size: 14px;
    color: #7E89A5;
}

.locale-field select,
.locale-field input {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    background: #f9f9f9;
}

.locale-time-format {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.time-format-options {
    display: flex;
    gap: 15px;
}

.time-format-options label {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
}

.locale-form-actions {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 20px;
}

.locale-cancel-btn {
    background: transparent;
    color: black;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #00000033;
    cursor: pointer;
    font-size: 14px;
}

.locale-save-btn {
    background: #1E88E5;
    color: white;
    padding: 10px 27px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 14px;
}
@media (min-width: 1600px) {
    .company-details-container {
        max-width: 80%;
    }

    .company-detail-form-group {
        gap: 5%;
    }

    .setting-description {
        width: 90%;
    }
}

/* Responsive Adjustments */
@media screen and (max-width: 576px) {
    .company-detail-form-group {
        flex-direction: column;
        gap: 10px;
    }

    .company-mobile-input select {
        width: 20%;
    }

    .company-mobile-input input {
        width: 100%;
    }

    .comapany-input-group {
        min-width: 100%; /* Stacks fields vertically on small screens */
        max-width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .company-detail-form-group {
        flex-direction: column;
    }

    .company-mobile-input select {
        width: 15%;
    }
}

@media screen and (max-width: 992px) {
    .company-detail-form-group {
        flex-wrap: wrap;
    }

    .comapany-input-group {
        min-width: 45%; /* Adjusts to two fields per row on medium screens */
        max-width: 45%;
    }
}

/* Mobile (575px and below) */
@media (max-width: 575px) {
    .company-details-container {
        padding: 10px;
    }

    .setting-company-tabs {
        flex-direction: column;
        align-items: flex-start;
    }

    .company-form-actions,
    .branding-form-actions,
    .locale-form-actions {
        flex-direction: column;
        align-items: stretch;
        gap: 10px;
    }

    .company-cancel-btn,
    .company-save-btn,
    .branding-cancel-btn,
    .branding-save-btn,
    .locale-cancel-btn,
    .locale-save-btn {
        width: 100%;
    }
}