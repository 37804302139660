.edit-job-container{
        width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
}
.edit-job-section-container{
    
    padding: 0 20px;
}
.edit-cancel-button{
    background-color: transparent;
    border: 1px solid #007bff;

  
}
.edit-cancel-button a{
    text-decoration: none;
}
.edit-save-changes-button{
    background-color: #007bff;
    color: white;
   

}
