.setting-create-user-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 10px; */
    border-radius: 5px;
}

.setting-create-user-search {
    display: flex;
    align-items: center;
    position: relative;
    width: 320px; 
    border: 1px solid #ccc;
    border-radius: 6px;
    background: #fff;
    padding: 2px 10px;
}
.setting-createuser-search-icon{
    font-size: 16px;
    color: #666;
    position: absolute;
    left: 10px;
}
.Setting-createsearch{
    width: 100%;
  padding: 8px 8px 8px 30px;
  border: none;
  outline: none;
  font-size: 14px;
  color: #333;
}
.setting-create-user-button .ftn {
    display: flex;
    align-items: center;
    gap: 8px; 
    color: #1E88E5;
    padding: 8px 20px;
    background-color: transparent;
    border: 1px solid #1E88E5;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.setting-create-user-button .ftn:hover {
    background-color: #1E88E5;
    color: white;
}

.setting-create-user-icon {
    color: #1E88E5;
    font-size: 16px; 
}
.setting-create-user-icon:hover{
    color: white;
}
.Create-User-hidden-form{
    position: absolute;
    top: 50%;
    left: 100%; 
    transform: translate(-50%, -50%) scale(0.9);
    width: 80%;
    height: auto;
    max-width: 600px;
    z-index: 9;
    background: white;
    padding: 0 0 20px 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    opacity: 0;
    z-index: 12;
    transition: left 0.5s ease-in-out, opacity 0.3s ease-in-out;
  
}
.create-User-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4); /* dark background with 40% opacity */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; /* make sure it’s on top */
}
.Create-user-show-form{
    left: 80%;
    opacity: 1;
    transform: translate(-55%, -50%) scale(1);
}

.Create-Access-role-modal-header{
    background-color: transparent;
    color: black;
    padding: 6px 15px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.Create-Access-role-modal-header button{
    background: none;
    border: none;
    color: black;
    font-size: 18px;
    cursor: pointer;
}
.setting-create-user-modal-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
    border-radius: 8px;
}

.setting-create-user-row {
    display: flex;
    gap: 16px;
}

.setting-create-user-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.setting-create-user-mobile {
    display: flex;
    gap: 8px;
    align-items: center;
}

.setting-create-user-code {
    width: 80px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.setting-create-user-footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 4%;
}

.setting-create-user-cancel {
    background: #ccc;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.setting-create-user-save {
    background: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.Create-User-Access-role-modal-footer {
    display: flex;
    /* background-color: red; */
    margin-right: 8.5%;
    justify-content: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.Create-User-Access-role-cancel-button, .Create-User-Access-role-save-button {
    padding: 8px 15px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.Create-User-Access-role-cancel-button {
    background: white;
    border: 1px solid #007bff;
    color: black;
}

.Create-User-Access-role-save-button {
    background-color: #007bff;
    color: white;
}
.settings-createuser-table-container {
    width: 100%;
    margin: 20px auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    background-color: white;
}

.settings-createuser-table-wrapper {
    max-height: 340px; 
    overflow-y: auto;
    overflow-x: hidden; 
    margin-top: 0;
    padding-top: 0;
}
/* For Webkit-based browsers (Chrome, Edge, Safari) */
.settings-createuser-table-wrapper::-webkit-scrollbar {
    width: 3px; 
}

/* .settings-createuser-table-wrapper::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 4px; 
}

.settings-createuser-table-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #555; 
} */
.setting-create-user-manager {
    padding: 10px 12px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    background-color: white;
    transition: background-color 0.2s ease;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .setting-create-user-manager:hover {
    background-color: #f9f9f9;
  }
  
  .setting-create-user-cancel-btn {
    background-color: #f5f5f5;
    color: #333;
    border: none;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 12px;
    margin-bottom: 6px;
    cursor: pointer;
    align-self: flex-end;
  }
  
  .setting-create-user-cancel-btn:hover {
    background-color: #e0e0e0;
  }
  
.settings-createuser-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0;
    padding-top: 0;
}

.settings-createuser-table thead {
    background-color: #f4f4f4;
    padding: 25px;
  
    position: sticky;
    top: 0;
    z-index: 2;
}

.settings-createuser-table th, 
.settings-createuser-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.settings-createuser-btn {
    display: block;
    margin: 10px auto;
    padding: 10px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.settings-createuser-btn:hover {
    background-color: #0056b3;
}
@media (max-width: 575px) {
    .setting-create-user-container {
        flex-direction: column;
        gap: 10px;
        align-items: stretch;
    }

    .setting-create-user-search {
        width: 100%;
        padding: 5px 10px;
    }

    .Setting-createsearch {
        font-size: 12px;
        padding-left: 25px;
    }

    .setting-create-user-button {
        width: 100%;
        text-align: center;
    }

    .setting-create-user-button .ftn {
        width: 100%;
        justify-content: center;
    }

    .Create-User-hidden-form {
        width: 95%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
    }

    .setting-create-user-modal-body {
        padding: 15px;
    }

    .setting-create-user-row {
        flex-direction: column;
        gap: 10px;
    }

    .setting-create-user-input {
        width: 100%;
    }

    .settings-createuser-table-wrapper {
        overflow-x: auto;
    }

    .settings-createuser-table {
        min-width: 600px;
        font-size: 12px;
    }

    .settings-createuser-btn {
        width: 100%;
        text-align: center;
    }
}

/* Tablet Devices (max-width: 786px) */
@media (max-width: 786px) {
    .setting-create-user-container {
        flex-wrap: wrap;
    }

    .setting-create-user-search {
        width: 80%;
    }

    .setting-create-user-button {
        width: auto;
    }

    .Create-User-hidden-form {
        width: 85%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .settings-createuser-table-wrapper {
        max-height: 300px;
    }

    .settings-createuser-table th,
    .settings-createuser-table td {
        padding: 6px;
        font-size: 13px;
    }
}



