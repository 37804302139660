

/* General Container */
.dynamic-text-input {
  display: flex;
  flex-direction: column;

  /* gap: 12px; */
  /* padding: 16px; */

  /* border: 1px solid #ddd; */
  border-radius: 4px;
  /* background: #fff; */
  /* width: 100%;
  max-width: 600px; */
}

/* Label Inputs */
.label-input,
.dynamic-label-input {
  /* width: 50%; */
  /* padding: 8px 12px; */
  background-color: transparent;
  font-size: 14px;
  border: 1px solid #CED4DA;
  border-radius: 4px;
  outline: none;
  transition: border 0.3s ease;
  /* background-color: white; */

}

.label-input:focus,
.dynamic-label-input:focus {
  border-color: #007bff;
}

/* Default Input Fields */
.default-input,
.decimal-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #CED4DA;
  border-radius: 4px;
  outline: none;
  transition: border 0.3s ease;
}


.default-input:focus,
.decimal-input:focus {
  border-color: #007bff;
}

/* Checkbox and Radio Inputs */
input[type="checkbox"]
input[type="radio"] {
  margin-right: 6px;
  cursor: pointer;
}

/* Radio & Checkbox Wrapper */
.checkBoxInput {
  display: flex;
  align-items: center;
  gap: 8px;
}
.radioInput{
  display: flex;
  flex-direction: row;
  border: 1px solid #CED4DA;
  /* background-color: red; */
  gap:10px;
  background-color: transparent;
}
.dropdown-select, .dropdown-select-wide {
  min-width: 100px ;
  max-width: 100%;  /* Ensures responsiveness */
  padding: 10px;
  font-size: 14px;
  border: 1px solid #CED4DA;
  border-radius: 4px;
  /* background: #fff; */
  outline: none;
  transition: border 0.3s ease, box-shadow 0.3s ease;
  appearance: none;
  cursor: pointer;
  background-color:transparent;
}
.dropdown-select-wide{
  text-align: center;
}

.custom-select-wrapper{
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-select-wrapper::after{
  content: "▾"; /* Unicode for a down arrow (V icon) */
  font-size: 14px;
  color: #333;
  position: absolute;
  top: 50%;
  right: 12px;
  pointer-events: none;
  transform: translateY(-50%);
}


/* Add focus effect */
.dropdown-select:focus, .dropdown-select-wide:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

.dropdown-container {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  width: 100%;
}

/* MultiSelect (React-Select) */
.multiselect-container {
  width: 100%;
  border:1px solid #CED4DA;
}

.multiselect-container .css-2b097c-container {
  width: 100%;
}

/* Quill Editor */
.editor-input .ql-toolbar {
  border-radius: 4px 4px 0 0;
}

.editor-input .ql-container {
  border-radius: 0 0 4px 4px;
  min-height: 30vh;
  border: none;
  min-width: 50vw;
}

/* File Upload */
input[type="file"] {
  padding: 8px;
}

/* Buttons */
button {
  padding: 8px 12px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

button:hover {
  opacity: 0.9;
 
}

/* Edit & Delete Buttons */
button.remove {
  background: #dc3545;
  cursor: not-allowed;
  color: #fff;
}

button.save {
  background: #28a745;
  color: #fff;
}

button.edit {
  background: #007bff;
  cursor: not-allowed;
  color: #fff;
}

/* Responsive Design */
@media (max-width: 600px) {
  .dynamic-text-input {
      max-width: 100%;
      padding: 12px;
  }
  
  .dropdown-select-wide {
      width: 100%;
  }
}
.default-input,
.decimal-input,
.dropdown-select,
.editor-input,
.checkBoxInput,
.dropdown-select-wide
.dynamic-label-input{
  border: 1px solid #CED4DA;

}
