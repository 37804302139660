.profile-pic {
  height: 280px;
  width: 280px;
  border: 1px solid #007bff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  background-color: #f5f7fa;
  position: relative; /* Needed for absolute positioning inside */
}

/* body{
  text-transform: capitalize;
} */
.create-canidate-profile-pic-img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ccc;
  background-color: #f5f5f5;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.create-candidate-select-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 180px; /* Push below the circular image */
  z-index: 1;
}

/* For Basic Info specifically */
.basic-info-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.profile-placeholder-icon {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: #f5f5f5;
  border: 2px solid #ccc;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: inherit;
  transform: translate(-50%, -50%);
}

.basic-info-fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
}

.circle{
  height: 200px;
  align-self: center;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  clip-path: circle(50% at 50% 50%);
  background-color: skyblue; 
}
.head{
  width: 20px;
  height: 20px;
  background-color:black;
  border-radius: 50px;
  border: 5px solid black;
}
.human-body{
  width: 60%;
  height: 60px;
  transform: translateY(-12px);
  border: 5px solid black;
  /* border-radius: 30; */
  background-color: black;
  clip-path: circle(39.3% at 50% 100%);
}
.candidate_alignment {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* Adjust margin to be consistent */
  margin-left: 0;
  padding: 0 20px;
}

.line{
  width: 95vw;
  height: 0.8px;
  translate: 0 -10px 0;
}

.candidate_section_name {
  display: flex;
  flex-direction: row;
  width: 95%;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px; /* Add spacing below the profile picture */
}
.create-candidate-addbtn{
  background-color: transparent;
  border: none;
  outline:none;
  color: #007bff;
}
.create-candidate-btnicon{
margin-top: auto;
}
.create-candidate-bottom-section{
  width: 100%;
  height: 10vh;
  background-color:#f5f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-candidate-bottom-section-save{
  background-color: #007bff;
  color: white;
  outline: none;
  margin-right: 1rem;
}
.create-candidate-bottom-section-cancel{
  background-color: transparent;
  color: black;
  border: 1px solid #007bff;
}
