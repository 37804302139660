.empty-box-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.empty-box-content-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 0.2rem;
    justify-content: center;
}
.empty-box-image{
    height: 150px;
    width:150px ;
}
.empty-box-header{
    font-weight:bold;
    font-size: 16px;
}
.empty-box-text{
    font-weight: 400;
    font-size: 14px;
}