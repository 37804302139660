@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.role-main-container {
    display: flex;
    flex-direction: column;
    width: 500px;
}

.role-create-textarea {
    height: 10%;
}

.role-table-container {
    overflow-x: scroll;
    overflow: auto;
}

.role-table {
    width: 100%;
    min-width: 800px;
    border-collapse: collapse;
}

.role-table-header {
    column-gap: 3px;
}
.select-dropdown-for-table{
    padding: 6px;
}

.role-dropdown-cell {
    align-self: center;
}

.role-checkbox-cell {
    align-self: center;
}
/* .Access-role-table {
    width: 100%;
    min-width: 800px;
    border-collapse: collapse;
}

.Access-role-table th, 
.Access-role-table td {
    border: 1px solid #D1D5DB; 
    padding: 8px; 
    text-align: center;
} */
.hidden-form {
    position: absolute;
    top: 60%;
    left: 100%; 
    transform: translate(-50%, -60%) scale(0.9);
    width: 80%;
    height: auto;
    max-width: 600px;
    z-index: 9;
    background: white;
    padding: 0 0 20px 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    opacity: 0;
    transition: left 0.5s ease-in-out, opacity 0.3s ease-in-out;
}
.hidden-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4); /* dark background with 40% opacity */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 8; /* make sure it’s on top */
  }

.show-form {
    left: 80%;
    opacity: 1;
    transform: translate(-60%, -50%) scale(1);
}

.Access-role-modal-header {
    background-color: transparent;
    color: black;
    padding: 6px 15px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.access-role-modal-header-btn{
    color: black;
}
.Access-role-modal-header button {
    background: none;
    border: none;
    color: black;
    font-size: 18px;
    cursor: pointer;
}

.Access-role-modal-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Access-role-modal-body label {
    font-weight: 600;
    font-size: 14px;
}

.Access-role-input-field{
    width: 90%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}
.Access-role-textarea-field {
    width: 90%;
    height: 100px;
}

.Access-role-textarea-field ::placeholder {
    font-family: 'Inter', Helvetica, 'Segoe UI', Arial, sans-serif !important;
}

.Access-role-modal-footer {
    display: flex;
    /* background-color: red; */
    margin-right: 8.5%;
    justify-content: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.Access-role-cancel-button, .Access-role-save-button {
    padding: 8px 15px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.Access-role-cancel-button {
    background: white;
    border: 1px solid #ccc;
    color: black;
}

.Access-role-save-button {
    background-color: #007bff;
    color: white;
}

.save-button:hover {
    background-color: #0056b3;
}

.Access-role-create-con .Access-role-create-button{
    display: flex;
    align-items: center;
    gap: 8px; 
    color: #1E88E5;
    padding: 8px 20px;
    background-color: transparent;
    border: 1px solid #1E88E5;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}
.setting-create-group-icon {
    color: #1E88E5;
    font-size: 16px; 
}
.Access-role-create-con .Access-role-create-button :hover{
    background-color: #1E88E5;
    color: white;

}
.Access-role-create-button {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 10px 30px;
    background-color: transparent;
    color: var(--fullblack);
    border: 1px solid #007bff;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
}

.Access-role-create-button:hover {
    background-color: #0056b3;
}
.Access-select-role-btn{
    display: flex;
    align-items: center;
    justify-content: space-between; 
    background-color: #1E88E529;
    padding: 8px 16px; 
    border: none;
    cursor: pointer;
    width: 100%; /* Ensures it stretches fully */
    text-align: left; /* Just in case */

}
.spantag-selected-role{
    display: block;
    font-size: 13px;
}
.Access-role-selection {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: bold;
  }
.Access-role-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .Access-role-popup {
    background: white;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: start;
    min-width: 550px;
    max-height: 400px; 
    overflow-y: auto;
  }

  .Access-role-popup-title {
    font-size: 14px;
    line-height: 16px;
    color: #71717A;
    margin-bottom: 10px;
  }

  .Access-role-popup-list {
    list-style: none;
    padding: 0;
    color: black;
    font-size: 14px;
    margin: 10px 0;
    border-bottom:1px solid #BADFFF;
  }

  .Access-role-popup-item {
    padding: 8px;
    cursor: pointer;
    border-radius: 5px;
    gap: 2%;
    background-color: var(--fullwhite);
    transition: background 0.2s ease-in-out;
  }
.Access-role-popup-item.active{
    background-color: #EBF6FF;
}

  .Access-role-popup-item:hover {
    background: #EBF6FF;
  }

  .Access-role-popup-close {
    display: block;
    margin:10px auto;
    padding: 8px 16px;
    align-items: center;
    background: transparent;
    color: var(--fullblack);
    border: 1px solid #BADFFF;
    cursor: pointer;
    border-radius: 5px;
  }

  .Access-role-popup-close:hover {
    background: #BADFFF;

  }
  .Access-role-save-changes-btn{
    margin-top: 2%;
    background-color:#007bff ;
    color: var(--fullblack);
  }
 .Access-role-cancel-btn{
    margin: 2% 2% 0 0;
    background-color:white;
    border: 1px solid #00000033;
    color: var(--fullblack);

 }

 @media (max-width: 575px) {
    .role-main-container {
        width: 100%;
        padding: 10px;
    }

    .role-table {
        min-width: 100%;
        font-size: 12px;
    }

    .hidden-form {
        width: 95%;
        padding: 15px;
    }

    .Access-role-modal-body {
        padding: 15px;
    }

    .Access-role-modal-header {
        font-size: 14px;
        padding: 8px 12px;
    }

    .Access-role-input-field {
        width: 100%;
    }

    .Access-role-textarea-field {
        width: 100%;
        height: 80px;
    }

    .Access-role-popup {
        min-width: 300px;
        padding: 15px;
    }
}

/* Tablet Devices (max-width: 786px) */
@media (max-width: 786px) {
    .role-main-container {
        max-width: 100%;
    }

    .role-table {
        min-width: 100%;
    }

    .hidden-form {
        width: 85%;
    }

    .Access-role-popup {
        min-width: 400px;
    }

    .Access-role-modal-body {
        padding: 18px;
    }
}

/* Large Screens (min-width: 1200px) */
@media (min-width: 1200px) {
    .role-main-container {
        max-width: 600px;
    }

    .role-table {
        min-width: 900px;
    }

    .hidden-form {
        max-width: 700px;
    }

    .Access-role-popup {
        min-width: 600px;
    }
}