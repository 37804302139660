@keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }


.interview-detail-popup-overlay{
    position: fixed;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    background-color:rgba(0, 0, 0, 0.4);
    z-index: 12;
  }
  
  .interview-detail-popup {
    position: fixed;
    top:9vh;
    right: 0px;
    width: 50vw;
    height: 90vh;
    background:var(--fullWhite);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 0 0 25px 0;
    border-radius: 8px;
    /* transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; */
    z-index: 10;
    opacity: 0;
    transform: translateX(100%);
    animation: slideIn 0.6s ease-in-out;
    pointer-events: none;
    display: flex;
  flex-direction: column;
}
  
  .interview-detail-popup.open {
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto;
    
  }
  
  .interview-detail-popup-header h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .interview-detail-popup .interview-detail-popup-close-btn {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color:var(--fullBlack);
  
    
  }
  
  .interview-detail-popup-header {
    display: flex;
    padding: 0 15px 0 15px;
    justify-content: space-between;
    background-color:var(--fullWhite);
    color:var(--fullBlack);
    border-radius: 8px 0px 0px 0px;
    border-bottom: 1px solid #ced4da;
    

  }
  .interview-detail-popup-content {
    flex: 1; 
    overflow-y: auto; 
    padding: 15px;
  
  }

  .interview-detail-popup-fixedcontainer {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--fullWhite); 
  }
  
  .interview-detail-popup-line{
    height: 1px;
    width: 100%;
    background-color: #007bff;
    margin: 0 0 1rem 0;
    
  }
  .interview-detail-popup-bottomCon{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .interview-detail-popup-cancelbtn{
    padding: 10px 15px;
    border-radius: 5px;
    background-color: transparent;
    color: black;
    border: 1px solid #007bff;
    margin-right: 0.5rem;
  }
  .interview-detail-popup-savebtn{
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
  }
  .interview-grid-wrapper {
    padding: 20px;
  }
  
  .interview-section{
    overflow-y: auto;
  }
  .interview-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 32px;
    
  }
  
  .interview-grid-row {
    display: flex;
    flex-direction: column;
  }
  
  .interview-label {
    font-size: 14px;
    color: #8898aa;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .interview-value {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    word-break: break-word;
  }
  
.interview-detail-link{
  color: #007bff;
  
}
  
