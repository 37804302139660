/* Wrapper around the entire SMTP settings panel */
.setting-smtp-control-wrapper {
  padding: 20px;
  max-width: 1000px;
  margin: auto;
}

/* Form field base style */
.setting-smtp-control-field {
  display: flex;
  flex-direction: column;
  width: calc(50% - 12px);
}

/* Label styling */
.setting-smtp-control-label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #333;
}

/* Input styling */
.setting-smtp-control-input {
  padding: 10px 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

/* Update button */
.setting-smtp-control-update-btn {
  margin-top: 16px;
  padding: 10px 16px;
  background: #1E88E5;

  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.setting-smtp-control-update-btn:hover {
  background-color: #3a6d8a;
}

/* Section for sending test mail */
.setting-smtp-control-test-mail {
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid #bbb;
}

/* Send test email button */
.setting-smtp-control-send-btn {
  margin-top: 10px;
  margin-left: 2%;
  padding: 10px 14px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.setting-smtp-control-send-btn:hover {
  background-color: #218838;
}

/* ---------- Card Layout ---------- */
.smtp-card-wrapper {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  overflow: hidden;
}

.smtp-card-header {
  background: #f5f7fa;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 15px;
  border-bottom: 1px solid #e0e0e0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 0;
}

.smtp-card-body {
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

/* Checkbox inline layout */
.setting-smtp-control-checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.setting-smtp-control-checkbox-label {
  font-size: 14px;
  color: #333;
}

/* ---------------------- Responsive Design ---------------------- */

@media (max-width: 1024px) {
  .setting-smtp-control-field {
    width: 100%;
  }

  .setting-smtp-control-input {
    width: 100%;
  }

  .smtp-card-body {
    flex-direction: column;
  }

  .setting-smtp-control-send-btn {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .setting-smtp-control-wrapper {
    padding: 16px;
  }

  .smtp-card-header {
    font-size: 14px;
    padding: 10px 14px;
  }

  .setting-smtp-control-label,
  .setting-smtp-control-checkbox-label {
    font-size: 13px;
  }

  .setting-smtp-control-update-btn,
  .setting-smtp-control-send-btn {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .smtp-card-wrapper {
    border-radius: 6px;
  }

  .setting-smtp-control-input {
    font-size: 13px;
    padding: 8px;
  }

  .setting-smtp-control-field {
    width: 100%;
  }
}
