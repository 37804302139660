@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.atsdashboardheader-wrapper {
  position: fixed;
  top: 57px; 
  width: 100%;
  z-index: 10; 
  background: #f7f7f7;
  /* box-shadow: 0px 4px 6px rgba(58, 53, 53, 0.1); */
}

.atsdashboardheader-container {
  display: flex;
  align-items: center;
  justify-content: start;
  background: #f8f9fc;
  padding: 5px 20px 0px 20px;

  height: 7vh;
  width: 100%;

 
}

.atsdashboardheader-icon-menu {
  display: flex;
  justify-content:start;
  padding: 0;
 
}



.atsdashboardheader-icon-button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
 
  border-right: 0.5px solid #ddd;
  height: 7vh;
}

.atsdashboardheader-icon-button:hover {
  background-color: white;
}

.atsdashboardheader-icon-wrapper.active::before {
  content: "";
  position: absolute;
  top: -0.4rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 5px;
  background-color: #4a85a8;
  /* border-radius: 2px; */
}

.atsdashboardheader-icon-button.active {
  background: white;
}

.atsdashboardheader-icon-wrapper {
  position: relative;
  flex-grow: 1;
  text-align: center;
}

.atsdashboardheader-tooltip {
  visibility: hidden;
  color: #130101;
  text-align: center;
  /* background-color: red; */
  font-size: 13px;
  padding: 5px 10px;
  /* border-radius: 4px; */
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 10;
  font-size: 13PX;
  color: black;
  background-color: white;
  border: 0.5px solid #A7D1FF;
  margin-top: 0.5rem;
}

/* .atsdashboardheader-icon-wrapper:hover .atsdashboardheader-tooltip {
  visibility: visible;
  opacity: 1;
 

} */

.atsdashboardheader-menu-icon {
  width: 20px;
  height: 20px;
  padding: 0 5px;
}

.menu-label {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter', Helvetica, 'Segoe UI', Arial, sans-serif;
  white-space: nowrap;
}
.atsdashboardheader-tooltip{
  visibility: hidden;
}
@media (min-width: 1090px) and (max-width: 1100px) {
  /* 125% zoom styles for 1366px screen */
  .atsdashboardheader-wrapper {
    top: 50px;
  }

  .atsdashboardheader-icon-wrapper.active::before {
    top: -7px;
  }

  .atsdashboardheader-container {
    padding-top: 6px;
  }
}
@media (min-width: 1240px) and (max-width: 1250px) {
  /* 110% zoom styles for 1366px screen */
  .atsdashboardheader-wrapper {
    top: 52px;
  }

  .atsdashboardheader-icon-wrapper.active::before {
    top: -6px;
  }

  .atsdashboardheader-container {
    padding-top: 5px;
  }
}

@media (min-width: 1515px) and (max-width: 1525px) {
  /* 90% zoom handling for 1366px screen */

  .atsdashboardheader-wrapper {
    top: 60px; /* adjust as needed */
  }

  .atsdashboardheader-icon-wrapper.active::before {
    top: -4px; /* tweak the blue bar if needed */
  }

  .atsdashboardheader-container {
    padding-top: 3px; /* fix spacing if layout shifts */
  }
}
@media (min-width: 1600px) {
  .menu-label {
    margin-left: 5px;
  }
  .atsdashboardheader-tooltip{
    visibility: hidden;
  }
  .atsdashboardheader-container{
    padding: 0px 20px 0px 20px;
    margin-top: 0;

  }
  .atsdashboardheader-wrapper {
    top: 71px; /* Adjust as needed */
  }
}
@media (min-width: 1815px) and (max-width: 1825px) {
  /* Your 75% zoom fix for 1366px screen */

  .atsdashboardheader-wrapper {
    top: 68px; /* or whatever adjustment needed */
  }

  .atsdashboardheader-icon-wrapper.active::before {
    top: -3px;
  }

  .atsdashboardheader-container {
    padding-top: 2px;
  }
}

@media (min-width: 1920px) {
  .menu-label {
    display: inline-block;
    margin-left: 5px;
  }
  .atsdashboardheader-tooltip{
    visibility: hidden;
  }

  .atsdashboardheader-wrapper {
    top: 68px; 
  }
}

@media (max-width: 575px) {
  .atsdashboardheader-container {
    flex-direction: column;
    align-items: center;
    background-color: red;
    padding: 5px 0 0 0;
  }

  .atsdashboardheader-wrapper {
    top: 94px;
  }

  .atsdashboardheader-icon-menu {
    display: flex;
    flex-wrap: nowrap; /* keep in single row */
    justify-content: center;
    width: 100%;
    padding: 5px 0;
  }
  .atsdashboardheader-icon-button {
    width: 100%;
    height: auto;
    padding: 6px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: none;
    background: transparent;
  }
}
