.setting-create-group-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 10px; */
    border-radius: 5px;
  
}

.setting-create-group-search {
    display: flex;
    align-items: center;
    position: relative;
    width: 320px; 
    border: 1px solid #ccc;
    border-radius: 6px;
    background: #fff;
    padding: 2px 10px;
}
.setting-creategroup-search-icon{
    font-size: 16px;
    color: #666;
    position: absolute;
    left: 10px;
}
.Setting-createsearch{
    width: 100%;
  padding: 8px 8px 8px 30px;
  border: none;
  outline: none;
  font-size: 14px;
  color: #333;
}
.setting-create-group-button .ctn {
    display: flex;
    align-items: center;
    gap: 8px; 
    color: #1E88E5;
    padding: 8px 20px;
    background-color: transparent;
    border: 1px solid #1E88E5;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.setting-create-group-button .ctn:hover {
    background-color: #1E88E5;
    color: white;
}

.setting-create-group-icon {
    color: #1E88E5;
    font-size: 16px; 
}
.setting-create-group-icon:hover{
    color: white;
}
.Create-group-container {
    margin-top: 2%;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
  
  .Create-group-card {
    width: 325px;
    border: 1px solid #ddd;
    background-color: #fff;
    padding: 10px;
  }
  
  .Create-group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 4px; /* Reduced padding */
    margin-bottom: 4px; /* Reduced margin */
  }
  
  .Create-group-header h3 {
font-weight: 400;
font-size: 16px;
line-height: 18px;
letter-spacing: 0%;

    margin: 0; /* Remove extra spacing */
    padding: 0;
  }
  
  .Create-group-icons {
    display: flex;
    gap: 6px;
  }
  
  .Create-group-icon {
    cursor: pointer;
    color: #888;
    font-size: 14px;
  }
  
  .Create-group-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-top: 10px; */
  }
  
  .Create-group-info {
    display: flex;
    flex-direction: column;
  }
  
  .Create-group-label {
    color: #AFAFAF;
    font-size: 12px;
    margin-bottom: 0;
  }
  
  .Create-group-lead {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0%;
    margin-top: 0;
    
  }
  
  .Create-group-team-icon {
    background-color: #e8f0ff;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hidden-group-form {
    position: absolute;
    top: 55%;
    left: 100%; 
    transform: translate(-50%, -50%) scale(0.9);
    width: 80%;
    height: auto;
    max-width: 600px;
    z-index: 10;
    background: white;
    padding: 0 0 20px 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    opacity: 0;
    transition: left 0.5s ease-in-out, opacity 0.3s ease-in-out;
}
.hidden-group-form-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4); /* dark background with 40% opacity */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9; /* make sure it’s on top */
}

.show-group-form {
    left: 80%;
    opacity: 1;
    transform: translate(-55%, -50%) scale(1);
}

.Access-group-role-modal-header {
    background-color: white;
    color: black;
    padding: 6px 15px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.Access-group-role-modal-header button {
    background: none;
    border: none;
    color: black;
    font-size: 18px;
    cursor: pointer;
}

.Access-group-role-modal-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
    width: 430px;
  }
  
  .Access-group-role-modal-body label {
    font-size: 14px;
    font-weight: 600;
    color: #67748e;
    margin-bottom: 5px;
    display: block;
  }
  
  .Access-group-role-modal-body input,
  .Access-group-role-modal-body select {
    width: 100%;
    padding: 10px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    font-size: 14px;
    outline: none;
    transition: border 0.2s ease-in-out;
    box-sizing: border-box;
  }
  .Access-group-role-modal-body select {
    appearance: none; /* Removes default browser styles */
    background-color: white;
  }
  
  .Access-group-role-modal-body input:focus,
  .Access-group-role-modal-body select:focus {
    border-color: #3b82f6;
  }
  
  .Access-group-role-modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 20px;
    border-top: 1px solid #e5e7eb;
  }
.Access-group-role-modal-footer {
    display: flex;
    /* background-color: red; */
    margin-right: 8.5%;
    justify-content: center;
    gap: 2%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.Access-group-role-save-button{
    margin-top: 2%;
    background-color:#1E88E5 ;
    color: white
  }
 .Access-group-role-cancel-button{
    margin: 2% 2% 0 0;
    background-color:white;
    border: 1px solid #1E88E5;
    color: var(--fullblack);
 }

/* Mobile (575px and below) */
@media (max-width: 575px) {
  .setting-create-group-container {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 10px;
  }

  .setting-create-group-search {
      width: 100%;
      max-width: 100%;
  }

  .Create-group-container {
      flex-direction: column;
      gap: 10px;
  }

  .Create-group-card {
      width: 100%;
  }

  .hidden-group-form {
      width: 95%;
      left: 50%;
      transform: translate(-50%, -50%);
  }

  .show-group-form {
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
  }

  .Access-group-role-modal-body {
      width: 100%;
      padding: 15px;
  }

  .Access-group-role-modal-buttons {
      flex-direction: column;
      align-items: center;
  }

  .Access-group-role-save-button,
  .Access-group-role-cancel-button {
      width: 100%;
      text-align: center;
  }
}