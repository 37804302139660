/* Global Reset */

  
  /* Single Setting Card */
  .single-setting-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 308px;
    gap: 15px;
    padding: 20px;
    background-color: #fff;
    overflow: hidden;
    word-wrap: break-word;
    min-height: 200px; /* Optional: ensure a baseline height */
  }
  
  /* Heading */
  .setting-main-heading {
    font-weight: 700;
    font-size: 19px;
    color: #6b7280;
    line-height: 24px;
    width: 100%;
    position: relative;
    padding-bottom: 10px;
    /* margin-bottom: 10px; */
  }
  
  .setting-main-heading::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.2px;
    background-color: #ddd;
  }
  
  /* Description */
  .setting-main-description {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6b7280;
    word-break: break-word;
  }
  
  /* Items Container */
  .setting-main-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  /* Individual Link Item */
  .setting-main-items .setting-main-page {
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #4b5563;
    text-decoration: none;
    padding: 6px 8px;
    border-radius: 6px;
    transition: background-color 0.2s ease;
    word-break: break-word;
  }
  
  .setting-main-page:hover {
    background: #1E88E524;
    cursor: pointer;

    color: #4b5563;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .single-setting-card {
      width: 90%;
      padding: 15px;
    }
  
    .setting-main-heading {
      font-size: 17px;
    }
  
    .setting-main-description {
      font-size: 11px;
    }
  
    .setting-main-items a {
      font-size: 13px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .single-setting-card {
      width: 100%;
      padding: 10px;
    }
  
    .setting-main-heading {
      font-size: 16px;
    }
  
    .setting-main-description {
      font-size: 10px;
    }
  
    .setting-main-items a {
      font-size: 12px;
    }
  }