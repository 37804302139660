.candidate-comment-section {
    max-width: 60vw;
}
.candidate-comment {
    margin-bottom: 20px;
  }
  
  .candidate-comment strong {
    display: inline-block;
  
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: medium;
    margin-right: 1rem;
  }
  
  .candidate-comment-date {
    color:#a1a6b4;
    font-size: 14px;
    margin-left: 5px;
    font-weight: regular;

  }
  .candidate-comment-message{
    font-size: 14px;
    font-weight: regular;
    color: #4b5563;
  }
  
  .candidate-add-comment-btn {
    padding: 10px 20px;
    background-color: white;
    border: 1px solid #0b74de;
    color: #0b74de;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .candidate-comment-box {
    margin-top: 20px;
  }
  
  .candidate-comment-box textarea {
    width: 100%;
    padding: 10px;
    font-size:14px;
    border-radius: 5px;
    height: 15vh;
    border: 1px solid #ccc;
    resize: none;
  }
  .candidate-comment-box textarea:focus{
    outline: none;
  }
  
  .candidate-comment-actions {
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }
  
  .candidate-cancel-btn, .candidate-submit-btn {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
  }
  
  .candidate-cancel-btn {
    border: 1px solid #0b74de;
    color: #333;
    background-color: transparent;
  }
  
  .candidate-submit-btn {
    border: 1px solid #0b74de;
    color: #0b74de;
    background-color: transparent;
  }
  .candidate-submit-btn:hover{
    background-color:#ebf6ff;
  }
  